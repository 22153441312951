<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
      <v-card>
        <v-col cols="12" class="my-auto" v-if="confirmpass">
          <div class="pa-6">
            <div class="mb-6">
              <v-row dense>
                <v-img
                  :src="require('@/assets/login/logo.png')"
                  max-width="75"
                ></v-img>
                <div class="ml-3">
                  <h3 class="font-weight-light">KONFIRMASI RESET PASSWORD</h3>
                  <strong>BKPSDM KABUPATEN POHUWATO</strong>
                </div>
              </v-row>
            </div>

            <div class="mb-6" v-if="alertG">
              <v-alert v-model="alertG" type="error" dense dismissible>
                {{ alertM }}
              </v-alert>
            </div>

            <form>
              <v-text-field
                v-model="password"
                label="Password"
                placeholder="Masukan Password Baru"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="token"
                label="Token"
                outlined
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn
                    class="mb-3"
                    color="utama"
                    dark
                    block
                    @click="resetpass()"
                  >
                    <span class="font-weight-black"> RESET PASSWORD </span>
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </v-col>

        <v-col cols="12" class="my-auto" v-else>
          <div class="pa-6">
            <div class="mb-6">
              <v-row dense>
                <v-img
                  :src="require('@/assets/login/logo.png')"
                  max-width="75"
                ></v-img>
                <div class="ml-3">
                  <h2 class="font-weight-light">LUPA PASSWORD</h2>
                  <strong>BKPSDM KABUPATEN POHUWATO</strong>
                </div>
              </v-row>
            </div>

            <div class="mb-6" v-if="alertG">
              <v-alert v-model="alertG" type="error" dense dismissible>
                {{ alertM }}
              </v-alert>
            </div>

            <form>
              <v-text-field
                v-model="username"
                label="Nomor Induk Pegawai"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                outlined
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn
                    class="mb-3"
                    color="utama"
                    dark
                    block
                    @click="lanjutkan()"
                    :loading="lbtn"
                  >
                    <span class="font-weight-black"> LANJUTKAN </span>
                  </v-btn>
                  <v-btn color="utama" dark text block @click="login()">
                    <span class="font-weight-black"> LOGIN </span>
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>
    
    <script>
export default {
  data: () => ({
    show: false,
    alertG: false,
    lbtn: false,

    confirmpass: false,

    title: process.env.VUE_APP_OPDNAME,
    logo: process.env.VUE_APP_OPDLOGO,

    appN: process.env.VUE_APP_FULLNAME,
    appLK: process.env.VUE_APP_APPLOKASI,
    appT: process.env.VUE_APP_APPNAME,
    appL: process.env.VUE_APP_APPLOGO,
    appFL: process.env.VUE_APP_FULLLOGO,

    alertM: "",

    username: "",
    password: "",
    email: "",
    token: "",

    data: "",
  }),

  methods: {
    lanjutkan() {
      this.lbtn = true;

      const data = {
        username: this.username,
        email: this.email,
      };

      const url = process.env.VUE_APP_AUTH + "forget-password";

      this.http
        .post(url, data)
        .then((res) => {
          this.lbtn = false;
          if (res.data.success) {
            this.alertG = false;
            this.confirmpass = true;
            this.data = res.data.mapData.data;
          } else {
            this.alertG = true;
            this.confirmpass = false;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.lbtn = false;
          this.alertG = true;
          this.confirmpass = false;
          this.alertM = err.response.data.message;
        });
    },

    resetpass() {
      this.lbtn = true;

      const data = {
        username: this.username,
        passwordBaru: this.password,
        token: this.token,
      };

      const url = process.env.VUE_APP_AUTH + "set-password";

      this.http
        .post(url, data)
        .then((res) => {
          this.lbtn = false;
          if (res.data.success) {
            this.alertG = false;
            this.$router
              .push("/login?client_id=" + this.$route.query.client_id)
              .catch(() => {});
          } else {
            this.alertG = true;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.lbtn = false;
          this.alertG = true;
          this.alertM = err.response.data.message;
        });
    },

    login() {
      this.$router
        .push("/login?client_id=" + this.$route.query.client_id)
        .catch(() => {});
    },
  },
};
</script>