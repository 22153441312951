<template>
  <v-row no-gutters>
    <v-col cols="6">
      <v-carousel cycle height="100vh" hide-delimiters>
        <v-carousel-item>
          <v-sheet height="100%">
            <v-img
              :src="require('@/assets/login/baner.jpg')"
              max-height="100vh"
              class="hidden-sm-and-down"
            ></v-img>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>

    <v-col cols="6" class="my-auto pa-10" v-if="confirmpass">
      <div class="mb-10">
        <v-row class="justify-center mb-5">
          <v-img
            :src="require('@/assets/login/logo.png')"
            max-width="100"
          ></v-img>
        </v-row>
        <div class="text-center">
          <h3 class="font-weight-light">
            BADAN KEPEGAWAIAN & PENGEMBANGAN SUMBER DAYA MANUSIA
          </h3>
          <h2>KABUPATEN POHUWATO</h2>
        </div>
      </div>

      <div class="mb-10" v-if="alertG">
        <v-alert v-model="alertG" type="error" dense dismissible>
          {{ alertM }}
        </v-alert>
      </div>

      <v-card class="pa-6">
        <div class="mb-5">
          <h3>KONFIRMASI RESET PASSWORD</h3>
        </div>
        <form>
          <v-text-field
            v-model="password"
            label="Password"
            placeholder="Masukan Password Baru"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            outlined
          ></v-text-field>
          <v-text-field v-model="token" label="Token" outlined></v-text-field>
          <v-row>
            <v-col cols="12" md="12">
              <v-btn class="mb-3" color="utama" block @click="resetpass()">
                <span class="font-weight-black"> RESET PASSWORD </span>
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-col>

    <v-col cols="6" class="pa-10 my-auto" v-else>
      <div class="mb-10">
        <v-row class="justify-center mb-5">
          <v-img
            :src="require('@/assets/login/logo.png')"
            max-width="100"
          ></v-img>
        </v-row>
        <div class="text-center">
          <h3 class="font-weight-light">
            BADAN KEPEGAWAIAN & PENGEMBANGAN SUMBER DAYA MANUSIA
          </h3>
          <h2>KABUPATEN POHUWATO</h2>
        </div>
      </div>

      <div class="mb-10" v-if="alertG">
        <v-alert v-model="alertG" type="error" dense dismissible>
          {{ alertM }}
        </v-alert>
      </div>

      <v-card class="pa-6">
        <div class="mb-5">
          <h3>LUPA PASSWORD</h3>
        </div>
        <form>
          <v-text-field
            v-model="username"
            label="Nomor Induk Pegawai"
            outlined
          ></v-text-field>
          <v-text-field v-model="email" label="Email" outlined></v-text-field>
          <v-row>
            <v-col cols="12" md="12">
              <v-btn
                class="mb-3"
                color="utama"
                block
                @click="lanjutkan()"
                dark
                :loading="lbtn"
              >
                <span class="font-weight-black"> LANJUTKAN </span>
              </v-btn>
              <v-btn color="utama" text block @click="login()">
                <span class="font-weight-black"> LOGIN </span>
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-col>
  </v-row>
</template>
    
    <script>
export default {
  data: () => ({
    show: false,
    alertG: false,
    lbtn: false,

    confirmpass: false,

    alertM: "",

    username: "",
    password: "",
    email: "",
    token: "",

    data: "",
  }),

  methods: {
    lanjutkan() {
      this.lbtn = true;

      const data = {
        username: this.username,
        email: this.email,
      };

      const url = process.env.VUE_APP_AUTH + "forget-password";

      this.http
        .post(url, data)
        .then((res) => {
          this.lbtn = false;
          if (res.data.success) {
            this.alertG = false;
            this.confirmpass = true;
            this.data = res.data.mapData.data;
          } else {
            this.alertG = true;
            this.confirmpass = false;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.lbtn = false;
          this.alertG = true;
          this.confirmpass = false;
          this.alertM = err.response.data.message;
        });
    },

    resetpass() {
      this.lbtn = true;

      const data = {
        username: this.username,
        passwordBaru: this.password,
        token: this.token,
      };

      const url = process.env.VUE_APP_AUTH + "set-password";

      this.http
        .post(url, data)
        .then((res) => {
          this.lbtn = false;
          if (res.data.success) {
            this.alertG = false;
            this.$router
              .push("/login?client_id=" + this.$route.query.client_id)
              .catch(() => {});
          } else {
            this.alertG = true;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.lbtn = false;
          this.alertG = true;
          this.alertM = err.response.data.message;
        });
    },

    login() {
      this.$router
        .push("/login?client_id=" + this.$route.query.client_id)
        .catch(() => {});
    },
  },
};
</script>