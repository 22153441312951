<template>
  <rpDekstop
    v-if="
      this.$vuetify.breakpoint.name == 'md' ||
      this.$vuetify.breakpoint.name == 'lg' ||
      this.$vuetify.breakpoint.name == 'xl'
    "
  />

  <v-container fill-height v-else>
    <rpMobile />
  </v-container>
</template>

<script>
import rpDekstop from "@/components/resetpass/rpDekstop";
import rpMobile from "@/components/resetpass/rpMobile";

export default {
  components: {
    rpDekstop,
    rpMobile,
  },

  data: () => ({}),
};
</script>